import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation from "./translation.json";

// import axios from "axios";
// // 나중에 admin page에 넣기
// const fetchLanguage = async () => {
//   try {
//     const response = await axios({
//       method: "GET",
//       url: "http://localhost:5001/multilingual",
//       responseType: "json",
//     });
//     console.log(response.data);
//     return response.data;
//   } catch (error) {
//     console.log(error.response);
//   }
// };
// fetchLanguage();

const resources = translation;

function Initi18n(langcode) {
  i18n.use(initReactI18next).init({
    resources,
    lng: langcode,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    cache: {
      enabled: true,
      expirationTime: 7 * 24 * 60 * 60 * 1000,
    },
  });
}

export default Initi18n;
